import OSS from "ali-oss";
import {
  compressImg,
  generateRandomNum,
  showLoading,
  hideLoading
} from "@/utils/index";
import { getOSSParam } from "@/api/common";
import { Loading } from "element-ui";
import storage from "@/utils/storage";
export default {
  props: {
    limit: {
      type: Number,
      default: null
    },
    // 只能传几张
    count: Number
  },
  data() {
    return {
      fileList: [],
      loadingList: [],
      toggleCropperDialog: false,
      previewImageUrl: "", //预览大图
      previewImageVisible: false, //预览大图
      ossParam: storage.get("fyb-oss-params", {}), //oss上传参数
      cropperOption: {
        img: "", // 裁剪图片的地址
        outputSize: 0.5, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        autoCrop: true, // 是否默认生成截图框
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [5, 4], // 截图框的宽高比例
        centerBox: true, //截图框是否被限制在图片里面
        enlarge: 1, //图片根据截图框输出比例倍数
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      imageSuffixes: [".webp", ".jpeg", ".jpg", ".png", ".gif"],
      // 文件类型对应的文件后缀
      suffixTypeMap: {
        excel: [".xlsx", ".xls"],
        word: [".doc", ".docx"],
        pdf: [".pdf"],
        image: [".webp", ".jpeg", ".jpg", ".png", ".gif"]
      }
    };
  },
  async created() {
    this.ossParam = await this.getOSSParam();
    this.$set(this.cropperOption, "fixedNumber", this.cropRatio);
  },
  methods: {
    async getOSSParam() {
      if (storage.get("fyb-oss-params")) {
        return storage.get("fyb-oss-params");
      }
      // 获取ossParam并缓存
      const res = await getOSSParam();
      if (res) {
        const expiration = parseInt(
          (new Date(res.expiration).getTime() - Date.now()) / 24 / 60 / 60
        );
        storage.set("fyb-oss-params", res, expiration);
        return res;
      }
      return {};
    },
    handlePreview(file) {
      this.previewImageUrl = file.url;
      this.previewImageVisible = true;
    },
    handleRemove(file, fileList = this.fileList) {
      //移除图片
      const index = fileList.findIndex(item => item.url == file.url);
      this.fileList.splice(index, 1);
      this.$emit("on-remove", this.fileList, file);
    },
    cropFinish() {
      //裁剪完成
      this.$set(
        this.cropperOption,
        "enlarge",
        this.imgW / this.$refs.cropper.cropW
      ); //根据需要输出的图片宽度和目前裁切框的宽度，计算输出比例，使输出图片尺寸跟预期图片尺寸一致
      this.$refs.cropper.getCropBlob(async file => {
        this.toggleCropperDialog = false;
        this.loadingList = [true];
        const params = await this.getOSSParam();
        let suffix = this.cropperOption.outputType;
        let fileName = `fmcms${Date.now()}-${generateRandomNum()}`;
        let client = new OSS({ ...params, secure: true }); //初始化oss
        fileName = `${params.file}/${fileName}.${suffix}`;
        await client
          .multipartUpload(fileName, file)
          .then(res => {
            let url = res.res.requestUrls[0];
            this.fileList = [{ url: url.split("?")[0] }];
            this.$emit("on-success", this.fileList);
          })
          .catch(() => {
            this.$showError("上传失败");
          });

        this.loadingList = [false];
      });
    },
    //上传图片 供父级调用 this.$refs.uploader.upload(file)
    async upload(file, isShowLoading) {
      isShowLoading && showLoading();
      this.ossParam = await this.getOSSParam();
      let suffix = file.type.split("/")[1];
      let fileName = `fmcms${Date.now()}-${generateRandomNum()}`;
      let client = new OSS({ ...this.ossParam, secure: true }); //初始化oss
      let resURL = "";
      suffix = suffix == "jpeg" ? "jpg" : suffix;
      fileName = `${this.ossParam.file}/${fileName}.${suffix}`;
      await client
        .multipartUpload(fileName, file)
        .then(res => {
          let url = res.res.requestUrls[0];
          resURL = url.split("?")[0];
        })
        .catch(async () => {
          this.$showError("上传失败");
          this.ossParam = await this.getOSSParam();
        });
      isShowLoading && hideLoading();
      return resURL;
    },
    async imgChangeNUploads(elFile) {
      try {
        const params = await this.getOSSParam();
        let file = elFile.raw; //压缩图片
        let point = file.name.lastIndexOf(".");
        let suffix = file.name.substr(point);
        let fileName = `fmcms${Date.now()}-${generateRandomNum()}`;
        let client = new OSS({ ...params, secure: true }); //初始化oss
        fileName = `${params.file}/${fileName}${suffix}`;
        let loading = Loading.service({
          //显示加载动画
          lock: true,
          background: "rgba(0, 0, 0, 0.7)"
        });
        // 分片上传文件
        let ret = await client.multipartUpload(fileName, file, {
          progress: async function(p) {
            let e = {};
            e.percent = p * 100;
          }
        });
        loading && loading.close(); //关闭加载动画
        if (ret.res.statusCode === 200) {
          this.fileList = [
            { url: ret.res.requestUrls[0].split("?")[0], name: file.name }
          ];
        } else {
          this.$showError("上传失败");
        }
      } catch (error) {
        this.$showError("上传失败");
      }
    },
    async imgChangeNUpload(elFile, fileList) {
      if (this.type == 6 && fileList.length > 1) {
        // 只能上传一个文件,再次上传覆盖之前的文件
        fileList.splice(0, 1);
      }
      // 限制文件格式
      const suffix_new = elFile.raw.name.substr(
        elFile.raw.name.lastIndexOf(".") + 1
      );
      if (this.accept.indexOf(suffix_new) === -1) {
        fileList.pop();
        this.$showError("文件格式错误");
        return;
      }
      // 限制文件大小
      const limitSize = this.limitSize * 1024 * 1024;
      if (elFile.size > limitSize) {
        fileList.pop();
        this.$showError(`文件不能大于${this.limitSize}M`);
        return;
      }
      const params = await this.getOSSParam();
      let file = elFile.raw;
      const point = file.name.lastIndexOf(".");
      const suffix = file.name.substr(point);
      let docType; // 文件后缀对应的文件类型
      let fileName = `fmcms${Date.now()}-${generateRandomNum()}`;
      let client = new OSS({ ...params, secure: true }); //初始化oss
      if (
        this.type == 2 &&
        this.isCrop &&
        this.imageSuffixes.includes(suffix)
      ) {
        //是否要裁剪，只支持单张上传时候
        this.cropperOption.img = URL.createObjectURL(elFile.raw);
        this.toggleCropperDialog = true;
      } else {
        if (this.type == 2 || this.type == 4 || this.type === 8) {
          fileList = [fileList.pop()];
          this.loadingList = [true];
        }

        // 如果需要压缩图片
        if (
          this.isCompress &&
          this.imageSuffixes.includes(suffix) &&
          suffix !== ".png"
        ) {
          file = await compressImg(elFile.raw); //压缩图片
        }
        const index = elFile.uid
          ? fileList.findIndex(item => item.uid == elFile.uid)
          : 0;
        fileName = `${params.file}/${fileName}${suffix}`;
        this.$set(this.loadingList, index, true);
        // 获取文件后缀对应的文件类型
        for (const key in this.suffixTypeMap) {
          if (this.suffixTypeMap[key].includes(suffix)) {
            docType = key;
            break;
          }
        }
        this.$set(fileList[index], "docType", docType);
        await client
          .multipartUpload(fileName, file, {
            partSize: 102400,
            progress: p => {
              if (this.showProcess) {
                // 获取上传进度
                fileList[index].percent = Math.round(p * 100);
                this.$emit("on-uploading", fileList);
                this.$forceUpdate();
              }
            }
          })
          .then(res => {
            let url = res.res.requestUrls[0];
            if (params.displayUrl) {
              //如果有置顶显示的host地址
              url = url.split("/file/")[1];
              url = `${params.displayUrl.replace(/\/$/, "")}/file/${url}`;
            }
            fileList[index].url = url.split("?")[0];
            fileList[index].relativeUrl = res.name;
          })
          .catch(async () => {
            fileList.pop();
            this.$showError("上传失败");
            this.ossParam = await this.getOSSParam();
          });
        this.$set(this.loadingList, index, false);
        this.fileList = fileList;
        this.$emit("on-success", this.fileList);
      }
    }
  }
};
