var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "close-on-click-modal": false,
      "title": "添加超链接",
      "width": "700px",
      "before-close": _vm.close
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "label-position": "left",
      "label-width": "100px",
      "model": _vm.formData
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "链接标题",
      "prop": "title",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "输入链接",
      "prop": "link",
      "rules": [_vm.$formRules.required(), _vm.linkRule]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入http://或https://开头的可用域名链接"
    },
    model: {
      value: _vm.formData.link,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "link", $$v);
      },
      expression: "formData.link"
    }
  }), _c('p', {
    staticClass: "tips"
  }, [_vm._v("可用域名 fangmao.com")])], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("确定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }