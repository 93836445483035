<template>
  <div class="x">
    <div class="menu opening">
      <el-select
        class="width"
        v-model="activityId"
        placeholder="请选择"
        clearable
      >
        <el-option
          v-for="(item, index) in openingList"
          :key="index"
          :label="item.activityName"
          :value="item.id"
        ></el-option>
      </el-select>
      <div class="mt20">
        <p class="fs16 bold lh40 bdb">
          {{ treeType ? "楼层-区域" : "总平图" }}
        </p>
        <el-tree
          v-if="treeType"
          node-key="id"
          :data="treeData"
          check-strictly
          :props="defaultProps"
          show-checkbox
          @check-change="handleClick"
          ref="treeForm"
        ></el-tree>
      </div>
    </div>
    <div class="main flex1 opening x">
      <div class="img-box flex1">
        <div v-show="!imagePath" class="width height y1 aic">
          <img style="width:100px;" src="../../../assets/addImg.png" />
          <p class="color6 mt8">请上传总平图</p>
        </div>
        <div
          v-show="imagePath"
          id="img-cell"
          class="img-cell"
          :style="imgStyle"
          @mousedown="e => start(e, 'img')"
          @mouseup="stop"
          @mousemove="move"
        >
          <img :src="imagePath" :draggable="false" />
          <div
            class="img-tag pointer y aic"
            :id="`tag${item.buildingId}`"
            v-for="(item, index) in showTagList"
            :key="item.buildingId"
            :style="{
              'z-index': activeTag == item.buildingId ? 99 : index,
              left: `${item.coordinateLeft}px`,
              top: `${item.coordinateTop}px`
            }"
            :class="{ active: activeTag == item.buildingId }"
            @mousedown.stop="e => start(e, item.buildingId)"
            @mouseup.stop="stop"
            @mousemove.stop="move"
            @dblclick="removeTag(item)"
          >
            <div class="tag-main x1 aic">
              {{ item.name }}
            </div>
            <div class="tag-triangle"></div>
          </div>
        </div>
      </div>
      <div class="setting height y3">
        <div>
          <p class="fs16 bold lh40 bdb mb8">楼层-区域</p>
          <p class="fs13 color9 mb12">
            最小尺寸800px * 600px，推荐比例4:3，支持jpg、png格式，建议大小5M以内
          </p>
          <f-upload
            type="4"
            ref="picUploader"
            class="mb8"
            title="创建总平图"
            :isCompress="false"
            @on-success="imgUploadSuccess"
          ></f-upload>
          <div class="h40 bdb mb8 x3 aic">
            <p class="fs16 bold">设置区域位置坐标</p>
            <el-button
              v-show="checkedItem.layoutType !== 'PARKING'"
              type="primary"
              size="mini"
              @click="autoImport"
              :loading="btnDis"
            >
              自动识别
            </el-button>
          </div>
          <template v-if="imagePath">
            <div class="tag-box">
              <div
                class="tag pointer x1 aic"
                v-for="(item, index) in showBlockList"
                :key="index"
                @click="swithTag(item)"
              >
                {{ item.name || item.parkingPlaceNo }}
              </div>
            </div>
            <div
              class="x1"
              v-if="
                checkedItem.layoutType !== 'PARKING' &&
                  blockCheckList.length > showBlockNum
              "
            >
              <el-button
                type="text"
                :disabled="pageIndex <= 1"
                @click="setPage(false)"
              >
                <i class="el-icon-caret-top"></i>
              </el-button>
              <el-button
                type="text"
                :disabled="showNextBtn"
                @click="setPage(true)"
              >
                <i class="el-icon-caret-bottom"></i>
              </el-button>
            </div>
          </template>
        </div>
        <div>
          <div class="fs13 color9 mb12">
            <p>操作说明</p>
            <p>第一步：点击右侧区域标签，生成到总平图；</p>
            <p>
              第二步：按住区域标签，拖动到对应位置，系统将自动获取该位置坐标值，然后点击保存按钮；
            </p>
          </div>
          <el-button
            type="primary"
            class="width"
            :disabled="!imagePath"
            @click="save"
          >
            保存
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOpeningList,
  getPlaneImageInfo,
  planeImageSaveOrUpdate
} from "@/api/openQuotationActivity";
import { parkingTree, parkingPlacePage, houseLayoutTree } from "@/api/opening";
import axios from "axios";

import FUpload from "@/components/upload";
const tag = {
  buildingId: "",
  coordinateLeft: 350,
  coordinateTop: 250,
  name: ""
};
export default {
  components: {
    FUpload
  },
  data() {
    return {
      activityId: "",
      treeData: [],
      treeType: null,
      defaultProps: {
        children: "children",
        label: "name"
      },
      openingList: [],
      checkedItem: {},
      queryData: {
        sort: "ID_ASC",
        pageIndex: 1,
        pageSize: 9999
      },
      total: 0,
      blockList: [],
      dragInfo: {
        canDrag: false,
        x0: 0,
        y0: 0,
        x1: 0,
        y1: 0
      },
      tagList: [],
      imgStyle: null,
      dragType: "",
      activeTag: "",
      imagePath: "",
      planeId: "",
      btnDis: false,
      domLT: {
        /*  left: 35,
        top: 30 */
        left: 0,
        top: 0
      },
      pageIndex: 1,
      showBlockNum: 5 * 5
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    },
    showTagList() {
      let list = this.$deepClone(this.tagList);
      list = list.map(e => {
        e.coordinateLeft -= this.domLT.left;
        e.coordinateTop -= this.domLT.top;
        return e;
      });
      return list;
    },
    showBlockList() {
      const { pageIndex, blockCheckList } = this;
      // 最多一次显示5*5
      let num = 25;
      let first = num * (pageIndex - 1);
      let last = num * pageIndex;
      let list = blockCheckList.slice(first, last);
      return list;
    },
    blockCheckList() {
      let checkList = this.blockList.filter(e => !e.checked);
      return checkList;
    },
    showNextBtn() {
      let flag = false;
      const { showBlockNum, pageIndex, showBlockList, blockCheckList } = this;
      let num = showBlockNum * (pageIndex - 1) + showBlockList.length;
      if (num >= blockCheckList.length) {
        flag = true;
      }
      return flag;
    }
  },
  watch: {
    tagList: {
      deep: true,
      handler() {
        this.setBlockCheck();
      }
    },
    activityId(e) {
      let item = this.openingList.find(el => el.id == e);
      if (item.activityType == 1) {
        this.parkingTree();
      } else {
        this.houseLayoutTree();
      }
    }
  },
  created() {
    this.getOpeningList();
  },
  methods: {
    setBlockCheck() {
      let list = this.tagList || [];
      let blockList = this.$deepClone(this.blockList);
      blockList.forEach(e => {
        e.checked = false;
        list.forEach(e2 => {
          if (e.id == e2.buildingId) {
            e.checked = true;
          }
        });
      });
      this.blockList = blockList;
    },
    async autoImport() {
      this.btnDis = true;
      const { imagePath, blockCheckList } = this;
      let list = [];
      blockCheckList.forEach(e => {
        if (!e.checked) {
          let item = {
            Id: e.id,
            Text: e.name || e.parkingPlaceNo
          };
          list.push(item);
        }
      });
      let url = `https://api.fangmao.com/extensionapi/ocrdetection`;
      let query = {
        ImageURL: imagePath,
        TxtMinHeight: 12,
        ContrastData: list
      };
      axios
        .post(url, query)
        .then(response => {
          this.btnDis = false;
          let data = response.data.data;
          if (!data) {
            return;
          }
          let list = data || [];
          let tagList = [];
          list.forEach(e => {
            const { id, text, succ, x, y, height, width } = e;
            if (succ) {
              let item = {
                buildingId: id,
                coordinateLeft: x + width / 2,
                coordinateTop: y + height / 2,
                name: text
              };
              tagList.push(item);
            }
          });
          this.tagList = [...this.tagList, ...tagList];
        })
        .catch(() => {
          this.btnDis = false;
        });
    },
    init() {
      this.imgStyle = `left:0px;top:0px`;
      this.dragInfo = {
        canDrag: false,
        x0: 0,
        y0: 0,
        x1: 0,
        y1: 0
      };
    },
    setPage(isNext) {
      if (isNext) {
        this.pageIndex++;
      } else {
        this.pageIndex--;
      }
    },
    swithTag(e) {
      let index = this.tagList.findIndex(el => el.buildingId == e.id);
      if (index >= 0) {
        this.tagList.splice(index, 1);
      } else {
        let item = this.$deepClone(tag);
        item.buildingId = e.id;
        item.name = e.name || e.parkingPlaceNo;
        this.tagList.push(item);
        this.activeTag = e.id;
      }
    },
    removeTag(e) {
      let index = this.tagList.findIndex(el => el.buildingId == e.buildingId);
      this.tagList.splice(index, 1);
    },
    chooseTag(e) {
      this.activeTag = e.id;
    },
    start(e, type) {
      //鼠标左键点击
      if (e.button == 0) {
        this.dragType = type;
        this.dragInfo.canDrag = true;
        //记录鼠标指针位置
        this.dragInfo.x0 = e.clientX;
        this.dragInfo.y0 = e.clientY;
        if (type != "img") {
          let item = this.blockList.find(el => el.id == type) || {};
          this.activeTag = item.id || this.activeTag;
        }
      }
    },
    stop() {
      this.dragInfo.canDrag = false;
    },
    move(e) {
      if (this.dragInfo.canDrag == true) {
        this.dragInfo.x1 = e.clientX;
        this.dragInfo.y1 = e.clientY;
        let x = this.dragInfo.x1 - this.dragInfo.x0;
        let y = this.dragInfo.y1 - this.dragInfo.y0;
        if (this.dragType == "img") {
          let img = document.querySelector("#img-cell");
          this.imgStyle = `left:${img.offsetLeft + x}px;top:${img.offsetTop +
            y}px`;
        } else {
          let index = this.tagList.findIndex(
            el => el.buildingId == this.dragType
          );
          if (index >= 0) {
            let img = document.querySelector(`#tag${this.dragType}`);
            this.tagList[index].coordinateLeft =
              img.offsetLeft + this.domLT.left + x;
            this.tagList[index].coordinateTop =
              img.offsetTop + this.domLT.top + y;
          }
        }
        this.dragInfo.x0 = this.dragInfo.x1;
        this.dragInfo.y0 = this.dragInfo.y1;
      }
    },
    async getOpeningList() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        let list = res || [];
        this.openingList = list;
        this.activityId = list[0].id;
      }
    },
    async parkingTree() {
      let query = {
        estateId: this.estateId
      };
      const res = await parkingTree(query);
      if (res) {
        this.treeData = res || [];
        this.treeType = 1;
        if (res[0]) {
          this.$nextTick(() => {
            this.$refs.treeForm.setCheckedNodes([res[0]]);
          });
        }
      }
    },
    async parkingPlacePage() {
      if (!this.queryData.areaId) {
        return;
      }
      const res = await parkingPlacePage(this.queryData);
      if (res) {
        const { total, list } = res;
        this.total = total;
        if (list.length) {
          this.blockList = list;
        }
      }
    },
    async houseLayoutTree() {
      let query = {
        estateId: this.estateId
      };
      const res = await houseLayoutTree(query);
      if (res) {
        let list = res || [];
        this.blockList = list;
        this.treeType = 0;
        this.init();
        this.initTag();
        this.getPlaneImageInfo();
      }
    },
    handleClick(data, checked) {
      if (checked == true) {
        this.init();
        this.initTag();
        this.$refs.treeForm.setCheckedNodes([data]);
        this.checkedItem = data;
        if (this.treeType == 1) {
          if (data.layoutType == "PARKING") {
            this.total = 0;
            this.blockList = data.children;
          } else {
            this.queryData.areaId = data.id;
            this.queryData.pageIndex = 1;
            this.parkingPlacePage();
          }
        }
        if (this.treeType == 0) {
          this.blockList = data.children;
        }
        this.getPlaneImageInfo();
      }
    },
    initTag() {
      this.tagList = [];
      this.imagePath = "";
      this.activeTag = "";
    },
    async getPlaneImageInfo() {
      let id = this.treeType == 1 ? this.checkedItem.id : 0;
      const res = await getPlaneImageInfo(this.activityId, id);
      if (res) {
        const { buildingList, imagePath, id } = res;
        this.imagePath = imagePath;
        this.tagList = buildingList || [];
        this.planeId = id;
      }
    },
    async save() {
      const {
        tagList,
        checkedItem,
        estateId,
        imagePath,
        planeId,
        activityId,
        treeType
      } = this;
      let query = {
        bindId: treeType == 1 ? checkedItem.id : 0,
        buildings: tagList,
        estateId,
        imagePath,
        planeId,
        planeType: 1,
        relationId: activityId
      };
      const res = await planeImageSaveOrUpdate(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.getPlaneImageInfo();
      }
    },
    imgUploadSuccess(e) {
      let item = e[e.length - 1];
      this.imagePath = item.url;
      this.tagList = [];
      this.init();
    }
  }
};
</script>

<style scoped lang="scss">
.opening {
  background: #fff;
  padding: 20px;
  min-height: 100%;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
}
.menu {
  width: 290px;
  margin-right: 20px;
}
.main {
  width: 1100px;
  .img-box {
    height: 560px;
    border: 1px solid #f2f2f2;
    margin-right: 20px;
    overflow: hidden;
    position: relative;
    .img-cell {
      position: absolute;
      left: 0;
      top: 0;
    }
    .img-tag {
      position: absolute;
      color: #333;
      transform: translate(-35px, -10px);
      .tag-main {
        width: 70px;
        height: 24px;
        background: #e8fde3;
        border-radius: 12px;
      }
      .tag-triangle {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 5px solid #e8fde3;
      }
      &.active {
        color: #ffffff;
        .tag-main {
          background: #ff0000;
        }
        .tag-triangle {
          border-top: 5px solid #ff0000;
        }
      }
    }
  }
  .setting {
    width: 300px;
  }
  .tag-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .tag {
      border: 1px solid #cccccc;
      width: 45px;
      height: 40px;
      background: #f2f2f2;
    }
  }
}
</style>
