import request from "@/utils/request";

export const CODE = {
  SUCCESS: 0,
  superadministrator: -11,
  appId: "wx6bea5aad8281f31b",
  cssHref: "https://vip.fangmao.com/static/css/qrCode.css",
  url: "https://vip.fangmao.com/#/workbench",
  redirect_uri: "https%3A%2F%2Fvip.fangmao.com/#/login",
  redirect_uri_url: "https%3A%2F%2Fsvip.fangmao.com",
  anchangUrl: "https://vip.fangmao.com/index.html"
};

// 获取短信验证码
export function getVerifyCode(data) {
  return request({
    url: `/api/v1/fyb/web/user/login/getVerifyCode`,
    method: "post",
    data
  });
}

// 手机号登录房易宝web
export function cellphoneLogin(data) {
  return request({
    url: `/api/v1/fyb/web/user/login/cellphoneLogin`,
    method: "post",
    data
  });
}

// 微信扫码登录房易宝web
export function weixinLogin(data) {
  return request({
    url: `/api/v1/fyb/web/user/login/weixinLogin`,
    method: "post",
    data
  });
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: `/api/v1/fyb/web/user/login/loginInfo`,
    method: "post",
    data
  });
}
