import Vue from "vue";
import { microAppListener } from "@/utils/microApp";
microAppListener();
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/utils/getCurrentInfo";
import "./filter";
import "./icons"; // icon
// import "./styles/element-variables.scss";
import styles from "@/styles/index.scss";
import ElementUI from "element-ui";
import VueAMap from "vue-amap";
import avatar from "./assets/icon_touxiang.png";
import FEmpty from "@/components/empty";
import FPagination from "@/components/pagination";
import FTableFields from "@/components/tableFields";
import FTable from "@/components/table";
import * as calc from "./utils/calc";
import { routeInterceptor } from "@/utils/interceptor";

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

import * as utils from "@/utils";
import * as config from "./config";
import { BASE_URL, DEFAULT_AVATAR_URL, OSS_URL } from "@/config";
import formRules from "@/utils/rules";
import storage from "@/utils/storage";
const {
  showSuccess,
  showError,
  deepClone,
  showConfirm,
  filterListTOName,
  getTreeNameById,
  debounce,
  openWindow,
  format
} = utils;
Vue.prototype.$BASE_URL = BASE_URL;
Vue.prototype.$styles = styles;
Vue.prototype.$showSuccess = showSuccess;
Vue.prototype.$showError = showError;
Vue.prototype.$deepClone = deepClone;
Vue.prototype.$showConfirm = showConfirm;
Vue.prototype.$defaultAvatar = avatar;
Vue.prototype.$formRules = formRules;
Vue.prototype.$filterListTOName = filterListTOName;
Vue.prototype.$getTreeNameById = getTreeNameById;
Vue.prototype.$calc = calc;
Vue.prototype.$time = moment;
Vue.prototype.$debounce = debounce;
Vue.prototype.$openWindow = openWindow;
Vue.prototype.$format = format;
Vue.prototype.$storage = storage;
Vue.prototype.$OSS_URL = OSS_URL;

const $f = {
  ...utils,
  ...config
};

// 全局混入变量
const globalMount = Vue => {
  Vue.prototype.$f = $f;
  Vue.mixin({
    computed: {
      // vuex userInfo
      $userInfo() {
        return this.$store.state.userInfo;
      },
      // 当前楼盘信息
      $estateInfo() {
        return this.$store.state.estateInfo;
      },
      // 当前平台信息
      $platformInfo() {
        return this.$store.state.platformInfo;
      },
      // 默认头像
      $defaultAvatarUrl() {
        return DEFAULT_AVATAR_URL;
      }
    },
    methods: {
      // 聚合平台（分销应用）业务配置权限 使用$platSs(1) $platSs([1, 2])  返回true证明有权限
      $platSs(val) {
        // 1基础应用 2自有渠道 3认购签约
        val = Array.isArray(val) ? val : [val];
        const { businessSetting } = this.$store.state.platformInfo;
        if (businessSetting) {
          const result = businessSetting.split(",").map(Number);
          return val.some(v => result.includes(v));
        }
        return false;
      }
    }
  });
};

Vue.use(globalMount);

Vue.component("f-empty", FEmpty);
Vue.component("f-pagination", FPagination);
Vue.component("f-table-fields", FTableFields);
Vue.component("f-table", FTable);

Vue.config.productionTip = false;

Vue.use(ElementUI);

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "d4a98a3357b97fac120711b187724ab3",
  // 插件集合
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder"
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4"
});
Vue.use(VueAMap);

routeInterceptor();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
